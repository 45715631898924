import React, { useEffect, useMemo, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import data from "./data.json";

function shuffle<T>(array: T[]) {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}
interface Stats {
    group: number;
    option: number;
    tries: number;
    allTime: number;
}

function App() {
    const [usedRanks, setUsedRanks] = useState<string[]>([]);
    const [index, setIndex] = useState(-1);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [onRefresh, setOnRefresh] = useState(false);
    const [categoryMiss, setCategoryMiss] = useState(false);
    const [optionMiss, setOptionMiss] = useState(false);
    const [stats, setStats] = useState<Stats>(
        localStorage.getItem("stats")
            ? JSON.parse(localStorage.getItem("stats") as string)
            : { group: 0, option: 0, tries: 0, allTime: 0 }
    );

    let ret: [number, number][] = [];

    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].options.length; j++) {
            ret.push([i, j]);
        }
    }

    const onRandomize = () => {
        let idx = Math.floor(ret.length * Math.random());
        while (usedRanks.some((k) => k == `${ret[idx][0]}-${ret[idx][1]}`)) {
            idx = Math.floor(ret.length * Math.random());
        }
        console.log(usedRanks);
        setIndex(idx);
        setCategoryMiss(false);
        setOptionMiss(false);
    };

    let categories = useMemo(() => shuffle(data.map((t) => t.name)), [index]);
    let options = useMemo(() => {
        let r = data.find((t) => t.name == selectedCategory)?.options.map((t) => t.name);
        if (r !== undefined) r = shuffle(r);
        return r;
    }, [index, selectedCategory]);

    useEffect(() => {
        if (selectedCategory && selectedCategory !== data[ret[index][0]].name) {
            setCategoryMiss(true);
        }
        if (selectedOption && selectedOption !== data[ret[index][0]].options[ret[index][1]].name) {
            setOptionMiss(true);
        }
    }, [selectedCategory, selectedOption]);

    useEffect(() => {
        onRandomize();
    }, [JSON.stringify(ret)]);

    useEffect(() => {
        localStorage.setItem(
            "stats",
            JSON.stringify({ group: 0, option: 0, tries: 0, allTime: stats.allTime })
        );
    }, [stats]);

    useEffect(() => {
        if (index == -1) return;
        if (selectedOption == data[ret[index][0]].options[ret[index][1]].name) {
            setSelectedCategory("");
            setSelectedOption("");
            setUsedRanks((t) => {
                console.log("here");
                if (t.length + 1 == ret.length) return [];
                return [...t, `${ret[index][0]}-${ret[index][1]}`];
            });
            setOnRefresh(true);
            setStats((t) => ({
                group: t.group + Number(!categoryMiss),
                option: t.option + Number(!optionMiss),
                tries: t.tries + 1,
                allTime: t.allTime + 1,
            }));
        }
    }, [selectedOption, index]);

    useEffect(() => {
        if (onRefresh) {
            onRandomize();

            setOnRefresh(false);
        }
    }, [onRefresh]);

    if (index == -1) {
        return <div></div>;
    }

    return (
        <div className="App" style={{ height: "100%" }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100dvh",
                    width: "100vw",
                    position: "relative",
                }}
            >
                <div style={{ position: "absolute", top: 0, right: 0, color: "white" }}>
                    <div>all time tries: {stats.allTime}</div>
                    <div>
                        current session: {usedRanks.length}/{ret.length}
                    </div>
                    <div>
                        group accuracy:{" "}
                        {stats.tries === 0 ? "-" : ((stats.group / stats.tries) * 100).toFixed(1)}%
                    </div>
                    <div>
                        specific accuracy:{" "}
                        {stats.tries === 0 ? "-" : ((stats.option / stats.tries) * 100).toFixed(1)}%
                    </div>
                </div>
                <div style={{ flex: 5, minHeight: 0 }}>
                    <img
                        style={{ height: "100%" }}
                        src={index !== -1 ? data[ret[index][0]].options[ret[index][1]].src : ""}
                        alt=""
                    />
                </div>
                <div style={{ flex: 7, minHeight: 0 }}>
                    {selectedCategory !== data[ret[index][0]].name ? (
                        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            {categories.map((name) => (
                                <div style={{ flex: 1, minHeight: 0 }}>
                                    <button
                                        onClick={() => setSelectedCategory(name)}
                                        style={{
                                            backgroundColor:
                                                name === data[ret[index][0]].name &&
                                                selectedCategory !== ""
                                                    ? "green"
                                                    : selectedCategory == name
                                                    ? "red"
                                                    : "#326fa8",
                                            height: "100%",
                                            width: "100%",
                                            margin: 0,
                                            color: "white",
                                            fontSize: 20,
                                        }}
                                    >
                                        {name}
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            {options?.map((name) => (
                                <div style={{ flex: 1, minHeight: 0 }}>
                                    <button
                                        onClick={() => setSelectedOption(name)}
                                        style={{
                                            backgroundColor:
                                                name ===
                                                    data[ret[index][0]].options[ret[index][1]]
                                                        .name && selectedOption !== ""
                                                    ? "green"
                                                    : selectedOption == name
                                                    ? "red"
                                                    : "#326fa8",
                                            height: "100%",
                                            width: "100%",
                                            margin: 0,
                                            color: "white",
                                            fontSize: 20,
                                        }}
                                    >
                                        {name}
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default App;
